
import { Vue } from "nuxt-property-decorator";
import { Mixin } from "vue-mixin-decorator";
import { namespace } from "vuex-class";

const ReportStore = namespace("report");


@Mixin
export default class ReportMixins extends Vue {
  @ReportStore.Getter getReports;
  @ReportStore.Getter getSpecifics;
  @ReportStore.Getter getSpecificsType;
  @ReportStore.Getter getReportAllType;
  @ReportStore.Getter getSpecificAllType;
  @ReportStore.Action getReportByType;
  @ReportStore.Action getSpecificById;
  @ReportStore.Action showReport;
  @ReportStore.Action hideReport;
  @ReportStore.Action sendReport;

  typeReports = {
    JOB: this.$t('report.report_job'),
    COMPANY: this.$t('report.report_company'),
    CANDIDATE: this.$t('report.report_candidate'),
    TRANSLATOR: this.$t('report.report_supporter'),
    BLOG: this.$t('report.report_post'),
    COMMUNITY: this.$t('report.report_question'),
  }
  
  get reports() {
    return this.getReports;
  }
  get specificReports() {
    return this.getSpecifics;
  }
}
