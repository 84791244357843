
import { Vue } from "nuxt-property-decorator";
import { Mixin } from "vue-mixin-decorator";

@Mixin
export default class ResizeDOM extends Vue {
  classParent = '';
  classChild = '';
  padingChild = 0;
  marginChild = 0;

  init(_classParent, _classChild, _padingChild = 0, _marginChild = 0) {
    this.classParent = _classParent;
    this.classChild = _classChild;
    this.padingChild = _padingChild;
    this.marginChild = _marginChild;

    this.adjustWidth();
    window.addEventListener('resize',this.adjustWidth);
  }
  adjustWidth() {
    const parentFixed = this.$el.querySelector(this.classParent);
    const parentwidth = parentFixed?.clientWidth ?? 0;
    const childs = this.$el.querySelectorAll(this.classChild);
    Array.from(childs).forEach(node => {
      node.setAttribute('style', `width:${parentwidth - this.padingChild - this.marginChild}px`);
    });
    this.$store.dispatch('navbar/setWidth', parentwidth - this.padingChild - this.marginChild);
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustWidth);
  }
}
