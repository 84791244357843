
import { Vue } from "nuxt-property-decorator";
import { Mixin } from "vue-mixin-decorator";

@Mixin
export default class EventDOMCustom extends Vue {
  disableFocus = false;
  
  mouseEnter(e) {
    this.disableFocus = true;
  }
  mouseLeave(e) {
    this.disableFocus = false;
  }
  onFocus(e) {
    if (!this.$isIE || this.disableFocus) {
      return false;
    }
    window.scrollBy({
      top: this.$el.getBoundingClientRect().top - this.$el.getBoundingClientRect().left,
      behavior: 'auto'
    });
  }
}
